
.cl-cut span {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    visibility: hidden;
}

.cl-cut:hover span {
    visibility: visible;
}
.group-image {
    cursor: pointer;
    position: relative;
    margin: 0 12px 12px 0;
}

.group-image img {
    border-radius: 12px;
}

.group-image-selected img {
    border: 4px #2177d1 solid;
}

.group-image-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.group-image:hover .group-image-overlay {
    background-color: rgba(255, 255, 255, 0.3);
}
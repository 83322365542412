
.content {
    padding: 12px 32px;
}

.imageCard {
    width: 12.5rem;
    float: left;
    margin-right: 24px;
    margin-bottom: 24px;
}
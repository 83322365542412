.previewImages-0 {
    --item-width: 159px;
    --item-height: 106px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
}

.previewImages-1 {
    --item-width: 320px;
    --item-height: 214px;
    width: var(--item-width);
    height: var(--item-height);
    padding: 0;
}

.previewImages-0.elevated {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 4px 4px 2px 4px;
    margin: 12px 0;
}

.previewImages .previewImage {
    object-fit: cover;
    border-collapse: collapse;
    padding: 0;
    width: var(--item-width);
    height: var(--item-height);
}

.previewImages img.previewImage {
    pointer-events: none;
}

.previewImages .previewImage.empty {
    position: relative;
    color: white;
    background-color: #ddd;
    display: inline-block;
}

.previewImages.elevated .previewImage.empty {
    border: 1px solid rgba(255, 255, 255, 0.8);
}

.previewImage .previewImagePlaceholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5em;
}

.previewImages.elevated:hover {
    background-color: #ddd;
    cursor: pointer;
}
.uploadImage {
    border: 4px #2196f3 solid;
    border-radius: 12px;
    text-align: center;
    line-height: 18px;
    padding: 24px;
    margin: 12px 0;
    cursor: pointer;
    color: #2196f3 !important;
}

.uploadImage:hover, .uploadImage:hover > * {
    background: #2196f3;
    color: white !important;
}

.imagePreview {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.imagePreviewLegend {
    height: auto;
    background: rgba(251, 103, 61, 0.8);
    padding: 8px 32px 8px 8px;
    position: absolute;
    bottom: 0;
    line-height: 18px;
    font-size: 0.9em;
    color: white;
    width: 100%;
    min-height: 52px;
    vertical-align: middle;
}

.imagePreviewLegend span {
    overflow-wrap: break-word;
}

.imagePreviewDelete {
    color: #ffffff !important;
    position: absolute !important;
    bottom:0;
    right: -8px;
    top:0;
    padding: 0;
    display: block !important;
}

.uploadProgress {
    text-align: center;
    min-height: 200px;
    min-width: 300px;
}

.uploadProgress > div {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
}

.wordsSearch {
    --image-width: 480px;
}

.wordsSearch .image {
    position: relative;
    margin-bottom: 24px;
}

.wordsSearch .image img {
    width: 480px;
    object-fit: contain;
}


.wordsSearch .crop {
    position: absolute;
    left: 0;
    height: calc(var(--image-width) * 0.5);
    width: 100%;
    margin: auto;
    border: 4px white dashed;
    box-shadow: 0 0 10px black;
}

.wordsSearch .crop.cropCenter {
    top: 0;
    bottom: 0;
}

.wordsSearch .crop.cropBottom {
    bottom: 0;
}

.wordsSearch .crop.cropTop {
    top: 0;
}

table.words {
    table-layout: fixed;
    max-width: 100%;
    overflow-x: scroll
}

table.words td, table.words th {
    padding: 4px 12px;
}

table.words td:nth-child(1), table.words th:nth-child(1) {
    padding: 4px 24px;
}

table.words tbody tr:hover td {
    background: #f8f8f8;
}

.bitLong {
    background-color: #fff4d8;
}

.tooLong {
    background-color: #fac6cc;
}

.menuItem.active {
    background: #2196f3;
}

.menuItem:hover {
    background: #2177d1 !important;
}

.menuItem.active:hover {
    background: #2177d1;
}

a {
    text-decoration: none;
}